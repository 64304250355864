import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Libraries
import _ from 'lodash'
import Modal from 'react-modal'

// CSS
import './Services.scss'

import Carousel from 'react-bootstrap/Carousel'

// Icon
import tick from 'img/icon/tick.svg'
import rightThinChevron from 'img/icon/right-thin-chevron.svg'
import cinema from 'img/icon/cinema.svg'

const Services = () => {
  const {
    allWordpressPage: { edges: services },
  } = useStaticQuery(graphql`
    {
      allWordpressPage(
        filter: { parent_element: { wordpress_id: { eq: 10 } } }
      ) {
        edges {
          node {
            path
            title
            acf {
              title
              points {
                title
              }
              videoURL: video_url
              quote
              thumbnail {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 400) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="services">
      {_.map(services, ({ node }, index) => {
        if (index % 2 === 0) {
          return <ServiceOdd key={index} node={node} />
        } 
          return <ServiceEven key={index} node={node} />
        
      })}
    </div>
  )
}

const ServiceOdd = ({ node: { title, path, acf } }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <div className="service service-odd py-5">
      <div className="container">
        <div className="row py-5">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-3" />
              <div className="col-lg-9 position-relative">
                <div
                  className="service-quote"
                  dangerouslySetInnerHTML={{ __html: acf.quote }}
                />
                <Carousel indicators={false} controls={false}>
                {acf.thumbnail.map((afbeelding) => (
                  <Carousel.Item>
                    <Img
                      className="service-image"
                      fluid={afbeelding.localFile.childImageSharp.fluid}
                    />
                  </Carousel.Item>
                ))}
                </Carousel>
              </div>
            </div>
          </div>
          <div className="col-lg-6 pl-lg-5 pt-lg-3">
            <div className="pl-lg-5 pt-5">
              <h2 className="font-size-xl">
                <div dangerouslySetInnerHTML={{ __html: acf.title }} />
              </h2>
              <div className="mt-5">
                {_.map(acf.points, ({ title }, index) => (
                  <div
                    className="service-point mt-3 font-size-sm font-family-secondary"
                    key={index}
                  >
                    <div className="row">
                      <div className="col-2 text-right">
                        <img className="service-tick d-inline-block" src={tick} alt="" />
                      </div>
                      <div className="col-10">{title}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-5">
                <button 
                  type="button"
                  onClick={() => {
                    openModal()
                  }}
                  className="service-video"
                >
                  <img src={cinema} alt="" />
                  Video
                </button>
                <Link
                  className="service-link px-3 font-size-sm font-weight-l text-uppercase"
                  to={path}
                >
                  Meer informatie 
                  {' '}
                  <img src={rightThinChevron} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Video"
        ariaHideApp={false}
      >
        <iframe
          src={acf.videoURL}
          title={title}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          width="640"
          height="360"
          autoPlay
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </Modal>
    </div>
  )
}

const ServiceEven = ({ node: { title, path, acf } }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <div className="service service-even py-5">
      <div className="container">
        <div className="row py-5">
          <div className="col-lg-6 pl-lg-5 pt-lg-3">
            <div className="pl-lg-5 pt-lg-5">
              <h2>
                <div dangerouslySetInnerHTML={{ __html: acf.title }} />
              </h2>
              <div className="mt-5">
                {_.map(acf.points, ({ title }, index) => (
                  <div
                    className="service-point mt-3 font-size-sm font-family-secondary"
                    key={index}
                  >
                    <div className="row">
                      <div className="col-2 text-right">
                        <img className="service-tick d-inline-block" src={tick} alt="" />
                      </div>
                      <div className="col-10">{title}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-5">
                <button 
                  type="button"
                  onClick={() => {
                    openModal()
                  }}
                  className="service-video"
                >
                  <img src={cinema} alt="" />
                  Video
                </button>
                <Link
                  className="service-link px-3 font-size-sm font-weight-l text-uppercase"
                  to={path}
                >
                  Meer informatie 
                  {' '}
                  <img src={rightThinChevron} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0">
            <div className="row">
              <div className="col-lg-9 position-relative">
                <div
                  className="service-quote"
                  dangerouslySetInnerHTML={{ __html: acf.quote }}
                />
                <Carousel indicators={false} controls={false}>
                {acf.thumbnail.map((afbeelding) => (
                  <Carousel.Item>
                    <Img
                      className="service-image"
                      fluid={afbeelding.localFile.childImageSharp.fluid}
                    />
                  </Carousel.Item>
                ))}
                </Carousel>
              </div>
              <div className="col-lg-3" />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Video"
        ariaHideApp={false}
      >
        <iframe
          src={acf.videoURL}
          title={title}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          width="640"
          height="360"
          autoPlay
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </Modal>
    </div>
  )
}

export default Services
