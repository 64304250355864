import React from 'react'
import { Link } from 'gatsby'

// CSS
import './RequestOffer.scss'

const RequestOffer = ({ isRed }) => (
  <div className={`request-offer${isRed ? ` request-offer-red` : ``}`}>
    <div className="request-offer-background-left" />
    <div className="container h-100 text-center">
      <div className="row h-100">
        <div className="request-offer-left col-5 col-lg-7 h-100">
          <h3 className="mt-1 font-size-l">
            <span className="d-none d-lg-inline-block">Vraag een</span>{' '}
            <strong>
              offerte
              <span className="d-none d-lg-inline-block">
                &nbsp;aan!
              </span>
            </strong>
          </h3>
        </div>
        <div className="request-offer-right col-7 col-lg-5">
          <Link
            className="request-offer-right-button button-hover px-4 py-3 text-uppercase font-size-sm font-weight-l"
            to="/offerte/"
          >
            Aanvragen
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default RequestOffer
